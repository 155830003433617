import { render, staticRenderFns } from "./CountIntro.vue?vue&type=template&id=47c97c79&scoped=true&"
import script from "./CountIntro.vue?vue&type=script&lang=js&"
export * from "./CountIntro.vue?vue&type=script&lang=js&"
import style0 from "./CountIntro.vue?vue&type=style&index=0&id=47c97c79&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "47c97c79",
  null
  
)

export default component.exports