<template>
    <div class="home-count-intro">
        <div class="home-count-intro-list">
            <div class="home-count-intro-item">
                <div class="icon"></div>
                <div class="count">{{$numberFormat(wineInfo.count)}}</div>
                <div class="title">파인 와인 보유량</div>
            </div>
            <div class="home-count-intro-item">
                <div class="icon"></div>
                <div class="count">{{transNumberK(wineInfo.total_price)}}</div>
                <div class="title">누적 파인 와인 금액</div>
            </div>
            <div class="home-count-intro-item">
                <div class="icon"></div>
                <div class="count">20</div>
                <div class="title">다년간의 경험</div>
            </div>
            <div class="home-count-intro-item">
                <div class="icon"></div>
                <div class="count">{{transNumberK(memberShipHolder)}}</div>
                <div class="title">멤버쉽 홀더</div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "CountIntro",
        props: {
            wineInfo:{}
        },
        data() {
            return {
                memberShipHolder:10000,
                totalWinePrice:108272890,
                stockWineCount:2183
            }
        },
        methods: {
            transNumberK(number) {
                if(number < 1000) {
                    return number;
                } else {
                    let transData = (number / 1000).toFixed()
                    transData = transData.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    return transData + "K"
                }

            }
        }
    }
</script>

<style scoped lang="scss">
@import "@/assets/scss/home-count-intro.scss";
</style>
